import { Ability } from "@casl/ability";
import jwtDecode from "jwt-decode";
import { initialAbility } from "./initialAbility";

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this

// const userData = JSON.parse(localStorage.getItem('userData'))
// const existingAbility = userData ? userData.ability : null

// export default new Ability(existingAbility || initialAbility)

const userData = JSON.parse(localStorage.getItem("userData"));

let ability;

if (userData) {
  const token = jwtDecode(userData.token);
  ability = token.ability;
  //console.log(token.ability);
}

const existingAbility = ability ? ability : null;

export default new Ability(existingAbility || initialAbility);

