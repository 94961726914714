export const SIGNUP = "SIGNUP";
export const SIGNOUT = "SIGNOUT";

export const setUserAuth = (
  firstName,
  lastName,
  email,
  mobile,
  gender,
  role,
  secureToken
) => {
  return {
    type: SIGNUP,
    firstName: firstName,
    lastName: lastName,
    email: email,
    mobile: mobile,
    gender: gender,
    role: role,
    secureToken: secureToken,
  };
};

export const setUserLogOut = () => {
  return {
    type: SIGNOUT,
  };
};
