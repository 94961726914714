import {SIGNOUT, SIGNUP} from '../actions/userAuth';

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  mobile: '',
  gender: '',
  role: '',
  secureToken: '',
};

const userAuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGNUP:
      return {
        firstName: action.firstName,
        lastName: action.lastName,
        email: action.email,
        mobile: action.mobile,
        gender: action.gender,
        role: action.role,
        secureToken: action.secureToken,
      };
    case SIGNOUT:
      return {
        initialState
      }
    default:
      return state;
  }
  return state;
};

export default userAuthReducer;
